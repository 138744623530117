import { Navigate, Route, Routes } from 'react-router-dom';
import AuthLayout from 'components/AuthLayout/AuthLayout';
import Login from 'pages/Login/Login';
import Roles from 'pages/Roles/Roles';
import Metrics from 'pages/Metrics/Metrics';
import Profile from 'pages/Profile/Profile';
import RoleStats from 'pages/RoleStats/RoleStats';
import Role from 'pages/Role/Role';
import Settings from 'pages/Settings/Settings';
import IntegrationsDashboard from 'pages/IntegrationsDashboard/IntegrationsDashboard';
import ForgotPassword from 'pages/ForgotPassword/ForgotPassword';
import ChangePassword from 'pages/ChangePassword/ChangePassword';
import SignUp from 'pages/SignUp/SignUp';
import Billing from 'pages/Billing/Billing';
import UserManagement from 'pages/UserManagement/UserManagement';
import Integration from 'pages/Integration/Integration';
import AdminLayout from 'components/AdminLayout/AdminLayout';
import OAuth from 'pages/OAuth/OAuth';
import OktaRedirect from 'pages/Okta/OktaRedirect';
import OktaCallback from 'pages/Okta/OktaCallback';
import useAnalyticsRouteChange from 'hooks/useAnalyticsRouteChange';
import Customizations from 'pages/Customizations/Customizations';
import Pricing from 'pages/Pricing/Pricing';
import Payment from 'pages/Payment/Payment';
import CompanyAlreadyExists from 'pages/CompanyAlreadyExists/CompanyAlreadyExists';
import GuestLayout from "../components/GuestLayout/GuestLayout";
import Security from 'pages/Security/Security';
import NotFound from 'pages/NotFound/NotFound';

function Router() {
  useAnalyticsRouteChange();

  return (
    <Routes>
      <Route element={<Login />} path={'/'} />
      <Route element={<AuthLayout />}>
        <Route path={'/404'} element={<NotFound />}  />
        <Route element={<AdminLayout />}>
          <Route element={<RoleStats />} path={'/role-stats'} />
        </Route>
        <Route element={<Settings />} path={'/settings'}>
          <Route element={<AdminLayout />}>
            <Route element={<IntegrationsDashboard />} path={'integrations'} />
            <Route element={<UserManagement />} path={'users'} />
            <Route element={<IntegrationsDashboard />} path={'integrations'} />
            <Route element={<Integration />} path={'integrations/:integrationId'} />
            <Route element={<Billing />} path={'billing'} />
            <Route element={<Security />} path={'security'} />
          </Route>
          <Route element={<Customizations />} path={'customizations'} />
        </Route>
        <Route element={<Roles />} path={'/roles'} />
        <Route element={<Role />} path={'/roles/:roleId'} />
        <Route element={<Metrics />} path={'/metrics'} />
        <Route element={<Profile />} path={'/profile'}>
          <Route element={<Profile />} path={':tab'} />
        </Route>
      </Route>
      <Route path={'/forgot-password'} element={<ForgotPassword />} />
      <Route path={'/password/reset/:token'} element={<ChangePassword />} />
      <Route path={'/signup'} element={<SignUp />} />
      <Route path={'/oauth'} element={<OAuth />} />
      <Route path={'/auth/okta/redirect'} element={<OktaRedirect />} />
      <Route path={'/auth/okta/callback'} element={<OktaCallback />} />
      <Route element={<GuestLayout />}>
          <Route path={'/pricing'} element={<Pricing />} />
      </Route>
      <Route path={'/payment'} element={<Payment />} />
      <Route path={'/company-already-exists'} element={<CompanyAlreadyExists />} />
      <Route path="*" element={<Navigate to={'/'} />} />
    </Routes>
  );
}

export default Router;
