import { useModals } from '@mantine/modals';
import { createStyles } from '@mantine/core';
import {
  AddUserToRolesProps,
  CloseRoleProps,
  CronofyMissingTokenProps,
  DeactivateUserProps,
  DisconnectModalProps,
  LeverAuthExpiredProps,
  LinkToATSProps,
  PauseRoleProps,
  ResumeRoleProps,
  RoleInfoProps,
  RoleLimitReachedProps,
  LaunchRoleProps,
  CancelPlanRenewalProps,
  InactivePlanProps,
} from 'types/modals';
import { useState } from 'react';

const useStyles = createStyles((theme) => ({
  inner: {
    // this is the black out screen behind the modal
    padding: 0,
  },

  modal: {
    overflowY: 'scroll',
    height: '100%',
    [theme.fn.largerThan('md')]: {
      height: 'fit-content',
      overflow: 'unset',
    },
  },

  body: {
    maxHeight: 'unset',
    overflow: 'unset',
    [theme.fn.largerThan('md')]: {
      height: 'unset',
      maxHeight: 'unset',
    },
  },

  atsModal: {
    height: '100%',
    width: '100%',
    [theme.fn.largerThan('md')]: {
      height: '100%',
      overflow: 'unset',
      width: '90%'
    },
    padding: '20px 20px 0px 20px !important'
  },

  atsModalBody: {
    height: '100%',
    maxHeight: '100%',
    display: 'flex',
    flexDirection: 'column'
  },

}));

const useOpenModal = () => {
  const { classes } = useStyles();
  const modals = useModals();

  const launchRole = (props: LaunchRoleProps) => {
    modals.openContextModal('launchRole', {
      innerProps: props,
      overflow: 'inside',
      closeOnClickOutside: false,
      classNames: {
        inner: classes.inner,
        modal: classes.modal,
        body: classes.body,
      }
    });
  };

  const videoTutorialModal = () => {
    modals.openContextModal('videoTutorial', {
      innerProps: {},
      overflow: 'inside',
      closeOnClickOutside: true,
      closeOnEscape: true,
      withCloseButton: false,
      classNames: {
        inner: classes.inner,
        modal: classes.modal,
        body: classes.body,
      },
      styles: {
        modal: { width: '602px', padding: '1px !important' },
      },
    });
  };

  const roleInfo = (props: RoleInfoProps) => {
    modals.openContextModal('roleInfo', {
      innerProps: props,
      closeOnClickOutside: false,
      overflow: 'inside',
      classNames: {
        inner: classes.inner,
        modal: classes.modal,
        body: classes.body,
      },
    });
  };

  const roleSetup = (props: any) => {
    modals.openContextModal('roleSetup', {
      innerProps: props,
      closeOnClickOutside: false,
      overflow: 'inside',
      classNames: {
        inner: classes.inner,
        modal: classes.modal,
        body: classes.body,
      },
    });
  };

  const contactSupport = () => {
    modals.openContextModal('contactSupport', {
      innerProps: {},
      overflow: 'inside',
      classNames: {
        inner: classes.inner,
        modal: classes.modal,
        body: classes.body,
      },
    });
  };

  const addUserToRoles = (props: AddUserToRolesProps) => {
    modals.openContextModal('addUserToRoles', {
      innerProps: props,
      overflow: 'inside',
      classNames: {
        inner: classes.inner,
        modal: classes.modal,
        body: classes.body,
      },
    });
  };

  const deactivateUser = (props: DeactivateUserProps) => {
    modals.openContextModal('deactivateUser', {
      innerProps: props,
      overflow: 'inside',
      classNames: {
        inner: classes.inner,
        modal: classes.modal,
        body: classes.body,
      },
    });
  };

  const inviteUser = () => {
    modals.openContextModal('inviteUser', {
      innerProps: {},
      overflow: 'inside',
      classNames: {
        inner: classes.inner,
        modal: classes.modal,
        body: classes.body,
      },
    });
  };

  const updatePayment = () => {
    modals.openContextModal('updatePayment', {
      innerProps: {},
      overflow: 'inside',
      classNames: {
        inner: classes.inner,
        modal: classes.modal,
        body: classes.body,
      },
    });
  };

  const pauseRole = (props: PauseRoleProps) => {
    modals.openContextModal('pauseRole', {
      innerProps: props,
      overflow: 'inside',
      classNames: {
        inner: classes.inner,
        modal: classes.modal,
        body: classes.body,
      }
    });
  };

  const resumeRole = (props: ResumeRoleProps) => {
    modals.openContextModal('resumeRole', {
      innerProps: props,
      overflow: 'inside',
      classNames: {
        inner: classes.inner,
        modal: classes.modal,
        body: classes.body,
      }
    });
  };

  const closeRole = (props: CloseRoleProps) => {
    modals.openContextModal('closeRole', {
      innerProps: props,
      overflow: 'inside',
      classNames: {
        inner: classes.inner,
        modal: classes.modal,
        body: classes.body,
      }
    });
  };

  const openRole = (props: CloseRoleProps) => {
    modals.openContextModal('openRole', {
      innerProps: props,
      overflow: 'inside',
      classNames: {
        inner: classes.inner,
        modal: classes.modal,
        body: classes.body,
      }
    });
  };

  const disconnectIntegration = (props: DisconnectModalProps) => {
    modals.openContextModal('disconnectIntegration', {
      innerProps: props,
      overflow: 'inside',
      classNames: {
        inner: classes.inner,
        modal: classes.modal,
        body: classes.body,
      },
    });
  };

  const gracePeriodExpired = () => {
    modals.openContextModal('gracePeriodExpired', {
      innerProps: {},
      overflow: 'inside',
      classNames: {
        inner: classes.inner,
        modal: classes.modal,
        body: classes.body,
      },
    });
  };

  const cronofyModal = () => {
    modals.openContextModal('cronofyAvailabilityRuler', {
      innerProps: {},
      overflow: 'inside',
      closeOnClickOutside: false,
      classNames: {
        inner: classes.inner,
        modal: classes.modal,
        body: classes.body,
      },
    });
  };

  const cronofyAuthExpiredModal = () => {
    modals.openContextModal('cronofyAuthExpiredModal', {
      innerProps: {},
      overflow: 'inside',
      closeOnClickOutside: false,
      classNames: {
        inner: classes.inner,
        modal: classes.modal,
        body: classes.body,
      },
    });
  };

  const leverAuthExpiredModal = (props: LeverAuthExpiredProps) => {
    modals.openContextModal('leverAuthExpiredModal', {
      innerProps: props,
      overflow: 'inside',
      closeOnClickOutside: false,
      classNames: {
        inner: classes.inner,
        modal: classes.modal,
        body: classes.body,
      },
    });
  };

  const cronofyMissingSetupModal = (props: CronofyMissingTokenProps) => {
    modals.openContextModal('cronofyMissingSetupModal', {
      innerProps: props,
      overflow: 'inside',
      closeOnClickOutside: false,
      classNames: {
        inner: classes.inner,
        modal: classes.modal,
        body: classes.body,
      },
    });
  };

  const roleLimitReachedModal = (props: RoleLimitReachedProps) => {
    modals.openContextModal('roleLimit', {
      innerProps: props,
      overflow: 'inside',
      closeOnClickOutside: false,
      classNames: {
        inner: classes.inner,
        modal: classes.modal,
        body: classes.body,
      },
    });
  };

  const inactivePlanModal = (props: InactivePlanProps) => {
    modals.openContextModal('inactivePlan', {
      innerProps: props,
      overflow: 'inside',
      closeOnClickOutside: false,
      classNames: {
        inner: classes.inner,
        modal: classes.modal,
        body: classes.body,
      },
    });
  };

  const linkToATSModal = (props: LinkToATSProps) => {
    modals.openContextModal('linkToATS', {
      innerProps: props,
      closeOnClickOutside: false,
      overflow: 'inside',
      classNames: {
        modal: classes.atsModal,
        body: classes.atsModalBody,
      }
    })
  }

  const contactSalesModal = (props: RoleLimitReachedProps) => {
    modals.openContextModal('contactSales', {
      innerProps: props,
      closeOnClickOutside: false,
      overflow: 'inside',
      classNames: {
        inner: classes.inner,
        modal: classes.modal,
        body: classes.body,
      },
    })
  }

  const cancelPlanRenewal = (props: CancelPlanRenewalProps) => {
    modals.openContextModal('cancelPlanRenewal', {
      innerProps: props,
      closeOnClickOutside: false,
      overflow: 'inside',
      classNames: {
        inner: classes.inner,
        modal: classes.modal,
        body: classes.body,
      },
    })
  }

  const provideFeedbackModal = (props: RoleLimitReachedProps) => {
    modals.openContextModal('provideFeedback', {
      innerProps: props,
      closeOnClickOutside: false,
      overflow: 'inside',
      classNames: {
        inner: classes.inner,
        modal: classes.modal,
        body: classes.body,
      },
    })
  }

  return {
    launchRole,
    roleInfo,
    roleSetup,
    contactSupport,
    addUserToRoles,
    deactivateUser,
    inviteUser,
    updatePayment,
    pauseRole,
    resumeRole,
    closeRole,
    openRole,
    disconnectIntegration,
    gracePeriodExpired,
    cronofyModal,
    cronofyAuthExpiredModal,
    leverAuthExpiredModal,
    cronofyMissingSetupModal,
    videoTutorialModal,
    roleLimitReachedModal,
    inactivePlanModal,
    linkToATSModal,
    contactSalesModal,
    cancelPlanRenewal,
    provideFeedbackModal
  };
};

export default useOpenModal;
