import React, { useMemo, useReducer } from 'react';
import { createStyles, Group, Text, Transition } from '@mantine/core';
import { useGetCompanyQuery } from 'app/services/rolebot';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-light-svg-icons';

const useStyles = createStyles((theme) => ({
  container: {
    justifyContent: 'space-between',
  },
  text: {
    fontSize: 15,
  },
}));

//create a function that gets a relative percentage of a value to another value
const getRelativePercentage = (value: number, max: number): number => {
  //first we reduce the decimals to 2
  let output = ((value / max) * 100).toFixed(2);
  //we take the decimals
  let decimals = output.toString().split('.')[1];
  // @ts-ignore
  if (decimals <= 50) {
    // @ts-ignore
    return Math.floor(output);
  } else {
    // @ts-ignore
    return Math.ceil(output);
  }
};

type RoleLimitAlertProps = {
  threshold: number;
};

const RoleLimitAlert = ({ threshold = 85 }: RoleLimitAlertProps) => {
  const { classes } = useStyles();
  const { data } = useGetCompanyQuery();
  const [open, close] = useReducer(() => false, true);
  const roleLimitPercentage = useMemo(() => {
    if (data && data.active_role_limit) {
      return getRelativePercentage(data.active_role_count, data.active_role_limit);
    }
    return -1;
    // eslint-disable-next-line
  }, [data]);

  return (
    <Transition transition={'slide-down'} mounted={roleLimitPercentage >= threshold && open}>
      {(styles) => (
        <div
          style={{
            ...styles,
            height: 35,
            backgroundColor: '#F4DCFF',
          }}
        >
          <Group sx={{ justifyContent: 'space-between', height: '100%' }} align={'center'} px={10} spacing={0} noWrap>
            <Text align={'center'} color={'#242424'} className={classes.text} mx={'auto'}>
              {roleLimitPercentage >= 100
                ? 'Your company has reached its role limit.'
                : `Your company has reached ${roleLimitPercentage}% of its role limit.`}
            </Text>
            <FontAwesomeIcon
              icon={faXmark}
              style={{ position: 'absolute', right: '20px' }}
              onClick={close}
              color={'#242424'}
              width={20}
            />
          </Group>
        </div>
      )}
    </Transition>
  );
};

export default RoleLimitAlert;
