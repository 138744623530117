import { Text, Group, Grid, createStyles, Divider, Tabs, ScrollArea } from '@mantine/core';
import { ContextModalProps, useModals } from '@mantine/modals';
import React, { useCallback, useEffect, useMemo, useReducer } from 'react';
import InitialDataStep from 'components/Modals/RoleInfo/InitialDataStep';
import HiringTeamStep from 'components/Modals/RoleInfo/HiringTeamStep';
import JobInfoStep from 'components/Modals/RoleInfo/JobInfoStep';
import { useGetCompanyQuery, useGetRoleByIdQuery, usePatchRoleInfoMutation, usePostRemoveSuggestionMutation } from 'app/services/rolebot';
import { useNotifications } from '@mantine/notifications';
import TitleWithClose from 'components/Modals/components/TitleWithClose';
import { RoleInfoProps } from 'types/modals';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faFileLines,
  faBriefcaseBlank,
  faLocationDot,
  faScreenUsers,
  faFileMagnifyingGlass,
  faUserPlus
} from '@fortawesome/pro-light-svg-icons';
import SearchCriteriaStep from "./SearchCriteriaStep";
import LocationStep from "./LocationStep";
import { useMediaQuery } from "@mantine/hooks";
import WaitingForSetupStepMenuItem from "./WaitingForSetupStepMenuItem";
import { RolebotClient } from "../../../api/client";
import RegularStepMenuItem from "./RegularStepMenuItem";
import RoleAdmins from "../RoleAdmins/RoleAdmins";
import RolebotTabs from "../../RolebotTabs/RolebotTabs";
import FeedbackTab from './FeedbackTab';
import RoleActivityTab from './RoleActivity/RoleActivityTab';
import { toastError, toastInfo, toastSuccess, toastWarning } from "../../../utils/toastify-messages";

const useStyles = createStyles((theme) => ({

  hiddenOnMobile: {
    display: 'none',
    [theme.fn.largerThan('md')]: {
      display: 'revert',
      marginTop: 10,
      marginBottom: 10
    },
  }
}));

const DEFAULT_VALUES = {
  first_profile_link: '',
  second_profile_link: '',
  ideal_talent_expectations: '',
  interviewer_id: null,
  interviewer: '',
  interviewer_title: '',
  calendly_link: '',
  team_size: '',
  target_salary: '',
  sell_opportunity: '',
  jd_link: '',
  preferred_experience_min: null,
  preferred_experience_max: null,
  office_locations: [],
  report_to_id: null,
  report_to: '',
  report_to_title: '',
  environment: '',
  bonus_offered: false,
  equity_offered: false,
  offer_relocation: false,
  visa_sponsorship: false,
  specific_location: true,
  remote_locations: [],
  remote_buffer: null,
  office_buffer: null,
  skills: [],
  nice_to_have_skills: [],
  excluded_skills: [],
  job_titles: [],
  excluded_job_titles: [],
  experience: [],
  languages: [],
  industries: [],
  certificates: [],
  keywords: [],
  companies: [],
  excluded_companies: [],
  prioritize_gender_diversity: false,
  custom_template: null,
  custom_template_edited_at: null,
  custom_template_edited_by: null,
  suggestedJobTitles: [],
  suggestedSkills: [],
  suggestedIndustries: [],
  suggestedYearsOfExperience: []
};

const MENU_STEP_ITEMS = [
  { id: 1, text: 'Initial data', step: 1, icon: faFileLines, iconWidth: '13px' },
  { id: 2, text: 'Job info', step: 2, icon: faBriefcaseBlank, iconWidth: '18px' },
  { id: 3, text: 'Location', step: 3, icon: faLocationDot, iconWidth: '13px' },
  { id: 4, text: 'Hiring team', step: 4, icon: faScreenUsers, iconWidth: '18px' },
  { id: 5, text: 'Search criteria', step: 5, icon: faFileMagnifyingGlass, iconWidth: '13px' }
];

const SOURCING_ONLY_MENU_STEP_ITEMS = [
  { id: 1, text: 'Initial data', step: 1, icon: faFileLines, iconWidth: '13px' },
  { id: 2, text: 'Location', step: 2, icon: faLocationDot, iconWidth: '13px' },
  { id: 3, text: 'Search criteria', step: 3, icon: faFileMagnifyingGlass, iconWidth: '13px' }
];

const Stepper = ({
  onClick,
  classes,
  step,
  showInfo,
  isDesktop,
  sourcing_only,
}: {
  onClick: (step: number) => void;
  classes: any;
  step: number;
  showInfo: boolean;
  isDesktop: boolean;
  sourcing_only: boolean;
}) => {
  const stepToCompare = sourcing_only ? 4 : 6;
  const menuItems = (showInfo && (step === stepToCompare)) ? [] : sourcing_only ? SOURCING_ONLY_MENU_STEP_ITEMS : MENU_STEP_ITEMS;
  const showRoleMembers = !showInfo || (step === stepToCompare && showInfo);
  return (
    <Group direction={'column'} position={'left'} mb={20} align={'start'} spacing={3} >
      {menuItems.map(x => (
        showInfo ?
          <WaitingForSetupStepMenuItem key={x.id} step={step} menuItem={x} isDesktop={isDesktop} /> :
          <RegularStepMenuItem onClick={(i) => onClick(i)} key={x.id} step={step} menuItem={x} isDesktop={isDesktop} />
      ))
      }
      {showRoleMembers &&
        <>
          {!showInfo && <Divider className={classes.hiddenOnMobile} style={{ border: '1px solid #DFE1E1', width: '100%' }} />}
          <RegularStepMenuItem onClick={(i) => onClick(i)} key={stepToCompare} step={step}
            menuItem={{ text: 'Role Members', step: stepToCompare, icon: faUserPlus, iconWidth: '18px' }}
            isDesktop={isDesktop} />
        </>
      }
    </Group>
  );
};

enum InfoActionTypes {
  NAVIGATE = 'NAVIGATE',
  UPDATE_STATE_VALUES = 'UPDATE_STATE_VALUES',
  NEXT = 'NEXT',
  PREV = 'PREV',
  SAVE = 'SAVE',
  SUBMIT = 'SUBMIT',
  RELOAD = 'RELOAD',
  RESET_NETWORK_STATE = 'RESET_NETWORK_STATE',
  IS_LOADING_CHANGED = 'IS_LOADING_CHANGED',
  SUGGESTIONS_CHANGED = 'SUGGESTIONS_CHANGED'
}

interface InfoState {
  step: number;
  save: boolean;
  isSubmitting: boolean;
  isLoading: boolean;
  showInfo: boolean;
  values: {
    first_profile_link: string;
    second_profile_link: string;
    ideal_talent_expectations: string;
    interviewer_id: number | null;
    interviewer: string;
    interviewer_title: string;
    report_to_id: number | null;
    report_to: string;
    report_to_title: string;
    calendly_link: string;
    team_size: string;
    target_salary: string;
    bonus_offered: boolean;
    equity_offered: boolean;
    offer_relocation: boolean;
    visa_sponsorship: boolean;
    sell_opportunity: string;
    jd_link: string;
    preferred_experience_min: any;
    preferred_experience_max: any;
    environment: string;
    office_locations: any;
    specific_location: boolean;
    remote_locations: any;
    remote_buffer: any;
    office_buffer: any;
    skills: any;
    nice_to_have_skills: any,
    excluded_skills: any;
    job_titles: any,
    excluded_job_titles: any,
    experience: any,
    languages: any,
    industries: any,
    certificates: any,
    keywords: any,
    companies: any,
    excluded_companies: any,
    prioritize_gender_diversity: boolean,
    custom_template: string | null,
    custom_template_edited_at: Date | null,
    custom_template_edited_by: string | null,
    suggestedYearsOfExperience: any
  };
}

const getDefaultIndustry = (data: any) => {

  if (data.customer?.industry) {
    return [data.customer?.industry]
  } else {
    return []
  }
}

const cleanValue = (value: string): string => {
  return value === 'N/A' || value === null ? '' : value;
};

const extractProfileLinksFromData = (data: any) => {
  return data && data.talent
    ? {
      first_profile_link: data.talent[0] ? data.talent[0].link : '',
      second_profile_link: data.talent[1] ? data.talent[1].link : '',
    }
    : {};
};

const buildProfileLinksForSubmit = (values: any) => {
  const { first_profile_link, second_profile_link } = values;
  return {
    ...(first_profile_link !== '' ? { first_profile_link } : {}),
    ...(second_profile_link !== '' ? { second_profile_link } : {}),
  };
};

const extractContacts = (data: any) => {
  if (!data || !data.info) return {};

  const interviewer = data.info.contacts.find((c: any) => c.role_contact_info.type === 'interviewer');
  const reporter = data.info.contacts.find((c: any) => c.role_contact_info.type === 'report_to');

  return {
    interviewer_id: interviewer ? interviewer.id : null,
    interviewer: interviewer ? interviewer.name : '',
    interviewer_title: interviewer ? interviewer.title : '',
    calendly_link: interviewer ? cleanValue(interviewer.calendly_link) : '',
    report_to_id: reporter ? reporter.id : null,
    report_to: reporter ? reporter.name : '',
    report_to_title: reporter ? reporter.title : '',
  };
};

const buildContacts = (values: any) => {
  const { interviewer, interviewer_title, report_to, report_to_title, calendly_link, report_to_id, interviewer_id } = values;

  const contacts = [];

  if (interviewer && interviewer_title) {
    contacts.push({
      id: interviewer_id,
      name: interviewer,
      title: interviewer_title,
      calendly_link,
      type: 'interviewer',
    });
  }

  if (report_to && report_to_title) {
    contacts.push({
      id: report_to_id,
      name: report_to,
      title: report_to_title,
      type: 'report_to',
    });
  }

  return {
    contacts,
  };
};

const formatRemotes = (remotes: any) => {
  return remotes.map((l: any) => ({ ...l, formatted_address: l.location_name }))
}

const formatOffices = (offices: any) => {
  return offices.map((l: any) => ({ ...l, value: l.location_name }))
}

const filterSkills = (skills: any, must_have: boolean) => {
  return skills.filter((skill: any) => {
    return Boolean(skill.pivot.nice_to_have) !== must_have && Boolean(skill.pivot.is_excluded) !== true
  })
}

const filterExcluded = (list: any, is_excluded: boolean) => {
  return list.filter((item: any) => {
    return Boolean(item.pivot.is_excluded) === is_excluded
  })
}

const filterCompanies = (companies: any, is_excluded: boolean) => {
  return companies.filter((company: any) => {
    return Boolean(company.pivot.is_excluded) === is_excluded
  })
}

const extractCommonValues = (data: any) => {
  if (!data || !data.info) return {};

  const {
    team_size,
    target_salary,
    bonus_offered,
    equity_offered,
    offer_relocation,
    visa_sponsorship,
    jd_link,
    preferred_experience_min,
    preferred_experience_max,
    ideal_talent_expectations,
    sell_opportunity,
    environment,
    specific_location,
    locations,
    offices,
    experience,
  } = data.info;

  const {
    skills = [],
    job_titles = [],
    languages = [],
    certificates = [],
    keywords = [],
    industries = [],
    companies = [],
    prioritize_gender_diversity = false
  } = data.user_searches.length > 0 ? data.user_searches[0] : {}


  return {
    team_size: cleanValue(team_size),
    target_salary: cleanValue(target_salary),
    bonus_offered,
    equity_offered,
    offer_relocation,
    visa_sponsorship,
    jd_link: cleanValue(jd_link),
    preferred_experience_min: cleanValue(preferred_experience_min),
    preferred_experience_max: cleanValue(preferred_experience_max),
    ideal_talent_expectations: cleanValue(ideal_talent_expectations),
    sell_opportunity: cleanValue(sell_opportunity),
    environment: cleanValue(environment),
    specific_location,
    office_locations: formatOffices(offices),
    remote_locations: formatRemotes(locations),
    remote_buffer: null,
    office_buffer: null,
    skills: filterSkills(skills, true),
    nice_to_have_skills: filterSkills(skills, false),
    excluded_skills: filterExcluded(skills, true),
    job_titles: filterExcluded(job_titles, false),
    excluded_job_titles: filterExcluded(job_titles, true),
    experience,
    languages,
    industries,
    certificates,
    keywords,
    companies: filterCompanies(companies, false),
    excluded_companies: filterCompanies(companies, true),
    prioritize_gender_diversity,
    custom_template: data.custom_template ?? null,
    custom_template_edited_at: data.custom_template_edited_at ?? null,
    custom_template_edited_by: data.custom_template_edited_by ?? null
  };
};

const buildCommonValues = (values: any) => {
  const {
    team_size,
    target_salary,
    equity_offered,
    bonus_offered,
    offer_relocation,
    visa_sponsorship,
    jd_link,
    preferred_experience_min,
    preferred_experience_max,
    ideal_talent_expectations,
    office_locations,
    sell_opportunity,
    environment,
    specific_location,
    remote_locations,
    remote_buffer,
    office_buffer,
    skills,
    nice_to_have_skills,
    excluded_skills,
    job_titles,
    excluded_job_titles,
    experience,
    languages,
    industries,
    certificates,
    keywords,
    companies,
    excluded_companies,
    prioritize_gender_diversity
  } = values;

  return {
    ...(team_size !== '' ? { team_size } : {}),
    ...(target_salary !== '' ? { target_salary } : {}),
    ...(equity_offered !== '' ? { equity_offered } : {}),
    ...(bonus_offered !== '' ? { bonus_offered } : {}),
    ...(offer_relocation !== '' ? { offer_relocation } : {}),
    ...(visa_sponsorship !== '' ? { visa_sponsorship } : {}),
    ...(jd_link !== '' ? { jd_link } : {}),
    ...(preferred_experience_min !== '' ? { preferred_experience_min } : {}),
    ...(preferred_experience_max !== '' ? { preferred_experience_max } : {}),
    ...(ideal_talent_expectations !== '' ? { ideal_talent_expectations } : {}),
    ...(office_locations !== '' ? { office_locations } : {}),
    ...(sell_opportunity !== '' ? { sell_opportunity } : {}),
    ...(environment !== '' ? { environment } : {}),
    ...(specific_location !== '' ? { specific_location } : {}),
    ...(remote_locations !== '' ? { remote_locations } : {}),
    ...(remote_buffer !== null ? { remote_buffer } : {}),
    ...(office_buffer !== null ? { office_buffer } : {}),
    ...(skills?.length ? { skills } : {}),
    ...(nice_to_have_skills?.length ? { nice_to_have_skills } : {}),
    ...(excluded_skills?.length ? { excluded_skills } : {}),
    ...(job_titles?.length ? { job_titles } : {}),
    ...(excluded_job_titles?.length ? { excluded_job_titles } : {}),
    ...(experience?.length ? { experience } : {}),
    ...(languages?.length ? { languages } : {}),
    ...(industries?.length ? { industries } : {}),
    ...(certificates?.length ? { certificates } : {}),
    ...(keywords?.length ? { keywords } : {}),
    ...(companies?.length ? { companies } : {}),
    ...(excluded_companies?.length ? { excluded_companies } : {}),
    ...({ prioritize_gender_diversity })
  };
};

const initReducer = (data: any) => {
  return {
    step: data?.step ? data.step : 1,
    save: false,
    isSubmitting: false,
    isLoading: false,
    showInfo: data?.show_info,
    values: {
      ...DEFAULT_VALUES,
      ...extractProfileLinksFromData(data),
      ...extractContacts(data),
      ...extractCommonValues(data),
    },
  };
};

const reducer = (state: InfoState, action: { type: InfoActionTypes; payload?: object; isLoading?: boolean }): InfoState => {
  switch (action.type) {
    case InfoActionTypes.UPDATE_STATE_VALUES:
      return {
        ...state,
        values: {
          ...state.values,
          ...action.payload
        }
      };
    case InfoActionTypes.NAVIGATE:
      return {
        ...state,
        // @ts-ignore
        step: action.payload
      };
    case InfoActionTypes.NEXT:
      return {
        ...state,
        step: state.step + 1,
        values: {
          ...state.values,
          ...action.payload,
        },
      };
    case InfoActionTypes.PREV:
      return {
        ...state,
        step: state.step - 1,
        values: {
          ...state.values,
          ...action.payload,
        },
      };
    case InfoActionTypes.SAVE:
      return {
        ...state,
        save: true,
        isSubmitting: true,
        values: {
          ...state.values,
          ...action.payload,
        },
      };
    case InfoActionTypes.SUBMIT:
      return {
        ...state,
        save: true,
        showInfo: false,
        isSubmitting: true,
        values: {
          ...state.values,
          ...action.payload,
        },
      };
    case InfoActionTypes.RESET_NETWORK_STATE:
      return {
        ...state,
        isSubmitting: false,
        save: false,
      };
    case InfoActionTypes.IS_LOADING_CHANGED:
      return {
        ...state,
        isLoading: action.isLoading
      } as InfoState;
    case InfoActionTypes.RELOAD:
      return initReducer(action.payload);
    case InfoActionTypes.SUGGESTIONS_CHANGED:
      return {
        ...state,
        values: {
          ...state.values,
          ...action.payload
        }
      }
    default:
      return state;
  }
};

const formatTitlesSuggestions = (suggestions: any[]) => {
  if (!suggestions.length) return [];

  return suggestions.map(suggestion => ({
    id: suggestion.id,
    job_title_id: suggestion.job_title.id,
    job_title_name: suggestion.job_title.name,
    role_id: suggestion.role_id
  }));
}

const formatSkillsSuggestions = (suggestions: any[]) => {
  if (!suggestions.length) return [];

  return suggestions.map(suggestion => ({
    id: suggestion.id,
    skill_id: suggestion.skill.id,
    skill_name: suggestion.skill.name,
    role_id: suggestion.role_id
  }));
}

const formatIndustriesSuggestions = (suggestions: any[]) => {

  if (!suggestions.length) return [];

  return suggestions.map(suggestion => ({
    id: suggestion.id,
    industry_id: suggestion.industry.id,
    industry_name: suggestion.industry.name,
    role_id: suggestion.role_id
  }));
}

const formatYearsOfExperienceSuggestions = (suggestion: any)=>{
  if (!suggestion) return [];

  return [{
    id: suggestion.id,
    role_id:suggestion.role_id,
    preferred_experience_min:suggestion.preferred_experience_min,
  }]
}

const RoleInfo = ({ context, id, innerProps }: ContextModalProps<RoleInfoProps>) => {
  const { roleId, roleName, initialStep, withCloseButton = false, withTitle = false, origin } = innerProps;

  console.log('innerProps', innerProps);

  const { classes } = useStyles();

  const isDesktop = useMediaQuery('(min-width: 992px)');

  const modals = useModals();

  const notifications = useNotifications();

  const { data, isFetching } = useGetRoleByIdQuery(roleId.toString(), { refetchOnMountOrArgChange: true });

  const [patchRoleInfo] = usePatchRoleInfoMutation();

  const [state, dispatch] = useReducer(reducer, initialStep, (data) => initReducer(data));

  const { data: company } = useGetCompanyQuery();

  const [tab, setTab] = React.useState(0);

  const [emailModified, setEmailModified] = React.useState(false);

  const showInfo = React.useRef<boolean | undefined>(undefined);
  const feedbackLoopCount = React.useRef<number | undefined>(undefined);

  const [availableSkillsSuggestions, setAvailableSkillsSuggestions] = React.useState(data?.search_criteria_skills_suggestions?.length ? formatSkillsSuggestions(data?.search_criteria_skills_suggestions) : []);
  const [availableTitlesSuggestions, setAvailableTitlesSuggestions] = React.useState(data?.search_criteria_titles_suggestions?.length ? formatTitlesSuggestions(data?.search_criteria_titles_suggestions) : []);
  const [availableIndustriesSuggestions, setAvailableIndustriesSuggestions] = React.useState(data?.search_criteria_industry_suggestions?.length ? formatIndustriesSuggestions(data?.search_criteria_industry_suggestions) : []);
  const [availableYearsOfExperienceSuggestions, setAvailableYearsOfExperienceSuggestions] = React.useState(data?.search_criteria_years_experience_suggestions ? formatYearsOfExperienceSuggestions(data?.search_criteria_years_experience_suggestions) : []);
  const [postRemoveSuggestion] = usePostRemoveSuggestionMutation();

  const [isCandidateLocationEmpty, setIsCandidateLocationEmpty] = React.useState(false);

  const isAdmin = useMemo(() => {
    return Boolean(company?.customer_user?.is_admin);
    // eslint-disable-next-line
  }, [company]);

  useEffect(() => {
    if (data !== undefined) {
      // send the current step to the RELOAD reducer action so that upon updating role info, we don't
      // get redirected back to step 1
      dispatch({ type: InfoActionTypes.RELOAD, payload: { ...data, step: state.step } });
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (data?.search_criteria_skills_suggestions || data?.search_criteria_titles_suggestions || data?.search_criteria_industry_suggestions || data?.search_criteria_years_experience_suggestions ) {
      dispatch({
        type: InfoActionTypes.SUGGESTIONS_CHANGED, payload: {
          suggestedSkills: data.search_criteria_skills_suggestions,
          suggestedJobTitles: data.search_criteria_titles_suggestions,
          suggestedIndustries: data.search_criteria_industry_suggestions,
          suggestedYearsOfExperience: data.search_criteria_years_experience_suggestions
        }
      });
    }
    setAvailableSkillsSuggestions(data?.search_criteria_skills_suggestions?.length ? formatSkillsSuggestions(data?.search_criteria_skills_suggestions) : [])
    setAvailableTitlesSuggestions(data?.search_criteria_titles_suggestions?.length ? formatTitlesSuggestions(data?.search_criteria_titles_suggestions) : [])
    setAvailableIndustriesSuggestions(data?.search_criteria_industry_suggestions?.length ? formatIndustriesSuggestions(data?.search_criteria_industry_suggestions) : [])
  }, [data?.search_criteria_skills_suggestions, data?.search_criteria_titles_suggestions, data?.search_criteria_industry_suggestions, data?.search_criteria_years_experience_suggestions]);

  useEffect(() => {
    if (initialStep) {
      // @ts-ignore
      dispatch({ type: InfoActionTypes.NAVIGATE, payload: initialStep });
    }
  }, [initialStep]);

  // This useEffect is relevant for roles that are waiting for setup, and is used for preparing a payload that determines
  // on role info form load, what step should be displayed based populated data.
  useEffect(() => {

    if (data?.show_info) {

      // @ts-ignore
      const createValueObject = (value, required = false) => ({ value, required });

      let payload = [];

      const initialDataValues = [
        createValueObject(data.talent[0]?.link, true),
        createValueObject(data.talent[1]?.link, true),
      ];

      const jobInfoValues = [
        createValueObject(data?.info?.target_salary, true),
        createValueObject(data?.info?.equity_offered),
        createValueObject(data?.info?.bonus_offered),
        createValueObject(data?.info?.jd_link),
        createValueObject(data?.info?.sell_opportunity, true),
      ];

      const locationValues = [
        createValueObject(data?.info?.environment, true),
        createValueObject(data?.info?.office_locations, true),
        createValueObject(data?.info?.offer_relocation),
        createValueObject(data?.info?.visa_sponsorship),
      ];

      const hiringTeamValues = [
        createValueObject(data?.info?.contacts, true),
      ];

      if (data?.sourcing_only) {
        payload = [
          { step: 1, values: initialDataValues },
          { step: 2, values: [...locationValues, createValueObject(data?.info?.target_salary, true)] }
        ];
      } else {
        payload = [
          { step: 1, values: initialDataValues },
          { step: 2, values: jobInfoValues },
          { step: 3, values: locationValues },
          { step: 4, values: hiringTeamValues },
        ];
      }

      determineCorrectStepToLoad(payload);
    }
  }, [data?.show_info]);

  // determines if should_auto_surface_talent flag should be set to true or false
  // that way we prevent an issue where we can initiate the auto surface talent script
  // on a step like "job info" or "hiring team"
  const getAutoSurfaceTalent = (sourcingOnly: unknown, step: number) => {
    return (sourcingOnly && (step === 3 || step === 2)) || (!sourcingOnly && step !== 2 && step !== 4);
  };

  useEffect(() => {
    if (showInfo.current === undefined && data?.show_info !== undefined) {
      showInfo.current = data.show_info;
    }
    if (feedbackLoopCount.current === undefined && data?.feedback_loop_count !== undefined) {
      feedbackLoopCount.current = data.feedback_loop_count;
    }
  }, [data]);

  //This will react to the save variable.
  useEffect(() => {
    if (state.save) {
      const payload = {
        ...buildProfileLinksForSubmit(state.values),
        ...buildCommonValues(state.values),
        ...buildContacts(state.values),
        show_info: state.showInfo === true,
        should_auto_surface_talent: getAutoSurfaceTalent(data?.sourcing_only, state.step),
        ...(state.step === (data?.sourcing_only ? 3 : 5) ? { is_sc_step: true } : {}),
        custom_template: state.values.custom_template,
        should_trigger_feedback_flow: innerProps.shouldTriggerFeedbackFlow
      };

      let responseShowInfo = null;
      let responseFeedbackMode = null;

      (async () => {
        try {
          await patchRoleInfo({
            roleId,
            payload,
          }).unwrap()
            // @ts-ignore
            .then((response) => {
              // @ts-ignore
              const role = response.role;
              console.log('role', role);
              if (innerProps.onSetupComplete) {
                innerProps.onSetupComplete(role.show_info, role.id);
              }
              if (innerProps.onAdjustSearchCriteriaComplete && role.feedback_mode === true) {
                innerProps.onAdjustSearchCriteriaComplete();
              }
              responseShowInfo = role.show_info;
              responseFeedbackMode = role.feedback_mode;
            });

          if (data?.show_info) {
            modals.closeAll();
          }

          //if they are different, we just completed the setup
          const isSetupComplete = responseShowInfo !== showInfo.current;

          if (isSetupComplete) {
            if(company?.feedback_flow_enabled) {
              toastSuccess(
                <div className="toastContainer">
                  <div>Seeking top talent</div>
                  <div
                    style={{
                      fontSize: '16px',
                      color: '#838485',
                      fontWeight: 400,
                      fontFamily: 'Helvetica',
                    }}
                  >
                    We're searching for the best candidates for your new role. You'll receive an update shortly!
                  </div>
                </div>
              );
            } else {
              toastSuccess(
                <div className="toastContainer">
                  <div>Success!</div>
                  <div
                    style={{
                      fontSize: '16px',
                      color: '#838485',
                      fontWeight: 400,
                      fontFamily: 'Helvetica',
                    }}
                  >
                    Role info updated successfully!
                  </div>
                </div>
              );
            }
          }

          if (!isSetupComplete) {
            //this is an update to the search criteria
            console.log('responseFeedbackMode', responseFeedbackMode);
            console.log('company?.feedback_flow_enabled', company?.feedback_flow_enabled);
            console.log('feedbackLoopCount.current', feedbackLoopCount.current);
            if (company?.feedback_flow_enabled && feedbackLoopCount.current !== undefined && feedbackLoopCount.current <= 3 && responseFeedbackMode === true) {
              toastWarning(
                <div className="toastContainer">
                  <div>Analyzing changes</div>
                  <div
                    style={{
                      fontSize: '16px',
                      color: '#838485',
                      fontWeight: 400,
                      fontFamily: 'Helvetica',
                    }}
                  >
                    Looking for sample matches with the new Search Criteria for "{data?.name}" role. This could take a couple of minutes.
                  </div>
                </div>
              )
            } else {
              toastSuccess(
                <div className="toastContainer">
                  <div>Role settings updated</div>
                  <div
                    style={{
                      fontSize: '16px',
                      color: '#838485',
                      fontWeight: 400,
                      fontFamily: 'Helvetica',
                    }}
                  >
                    Your changes have been saved and will be considered for upcoming talent searches. We'll notify you once we find new matches.
                  </div>
                </div>
              );
            }
          }

          if (emailModified) {
            toastInfo(
              <div className="toastContainer">
                <div>Important information</div>
                <div
                    style={{
                      fontSize: '16px',
                      color: '#838485',
                      fontWeight: 400,
                      fontFamily: 'Helvetica',
                    }}
                  >
                  Changes to the candidate outreach template may take up to 24 hours to be reflected and will not apply to any candidates contacted prior.
                </div>
              </div>,
              'email-template-change'
            )
          }

        } catch (e) {
          dispatch({ type: InfoActionTypes.RESET_NETWORK_STATE });
          let error = e as { data: { message: ''; errors: { key: string[] } } };

          let errorMessage = "Something went wrong.";
          let apiMessage = error?.data?.message;
          let errors = error?.data?.errors;

          if (apiMessage) {
            errorMessage = error?.data?.message;
            let validationMessages = [];
            for (const field in errors) {
              if (errors.hasOwnProperty(field)) {
                //@ts-ignore
                validationMessages.push(errors[field].join(' '));
              }
            }
            const concatenatedErrors = validationMessages.join('\n');
            errorMessage = apiMessage + " " + concatenatedErrors;
          } else {
            //@ts-ignore
            errorMessage = errors;
          }

          toastError(
            <div className="toastContainer">
              <div>Oops!</div>
              <div>{errorMessage}</div>
            </div>
          );
        }
      })();
    }
  }, [state.save]);

  const prevStep = (payload: object) => {
    dispatch({ type: InfoActionTypes.PREV, payload });
  };

  const nextStep = (payload: object) => {
    dispatch({ type: InfoActionTypes.NEXT, payload });
  };

  // Initiates the request Skills from Open AI based on role name and navigate to next page in the role info form
  const requestAiSkills = (payload: object) => {

    if (data?.show_info) {

      dispatch({ type: InfoActionTypes.IS_LOADING_CHANGED, isLoading: true });

      RolebotClient.post(`/roles/${roleId.toString()}/request-ai-skills`, {
        roleName: data?.name,
        // @ts-ignore
        firstProfileLink: payload?.first_profile_link,
        // @ts-ignore
        secondProfileLink: payload?.second_profile_link
      })
        .then((res) => {
          dispatch({ type: InfoActionTypes.NEXT, payload });
          dispatch({ type: InfoActionTypes.IS_LOADING_CHANGED, isLoading: false });
        })
        .catch((e) => {
          dispatch({ type: InfoActionTypes.NEXT, payload });
          dispatch({ type: InfoActionTypes.IS_LOADING_CHANGED, isLoading: false });
        });
    } else {
      dispatch({ type: InfoActionTypes.NEXT, payload });
    }
  };

  // Initiates the request Skills from Open AI based on role name and navigate to next page in the role info form
  const requestAiSkillsAndFinishLater = (payload: { first_profile_link?: string, second_profile_link?: string }) => {

    if (data?.show_info) {
      // check if payload.first_profile link and payload.second_profile_link are not empty
      if (payload?.first_profile_link && payload?.second_profile_link) {
        RolebotClient.post(`/roles/${roleId.toString()}/request-ai-skills`, {
          roleName: data?.name,
          // @ts-ignore
          firstProfileLink: payload?.first_profile_link,
          // @ts-ignore
          secondProfileLink: payload?.second_profile_link
        })
          .then((res) => {
            console.log(res);
            dispatch({ type: InfoActionTypes.SAVE, payload });
          })
          .catch((e) => console.log(e));
      } else {
        dispatch({ type: InfoActionTypes.SAVE, payload });
      }
    }
  };

  const save = (payload: object) => {
    dispatch({ type: InfoActionTypes.SAVE, payload });
  };

  const submit = (payload: object) => {
    dispatch({ type: InfoActionTypes.SUBMIT, payload });
  };

  // Called when a menu step component is unmounted when a role is not "waiting for setup". This approach solves an issue
  // where navigating to a user-chosen menu step component formerly wasn't updating the values in the role info form
  // state itself, causing loss of populated data upon navigating to a different menu step and coming back to previous
  // one(s)
  const handleUpdateStateValues = (payload: object) => {
    dispatch({ type: InfoActionTypes.UPDATE_STATE_VALUES, payload });
  };

  const handleCandidateLocationChanges = (payload: object | null) => {
    const isEmpty = payload === null;
    setIsCandidateLocationEmpty(isEmpty);
  }

  const handleMenuItemClicked = (step: number) => {
    // @ts-ignore
    dispatch({ type: InfoActionTypes.NAVIGATE, payload: step });
  };

  const determineCorrectStepToLoad = useCallback((payload: {
    step: number;
    values: any[];
  }[]) => {

    // Initialize step based on data.needs_info_update
    // We want to skip first step since it's not able to be modified
    // In that state of the role info form, we want to navigate to the second step
    // let step = data?.needs_info_update ? 2 : 1;
    let step = 1;
    let stepFound = false;

    if (!data?.needs_info_update) {
        for (const item of payload) {
          if (stepFound) break;
          let requiredItems = item.values.filter((x: any) => x.required);
          for (const value of requiredItems) {
            if (!value.value?.length || !Object.keys(value.value || {})?.length || value.value === 'N/A') {
              step = item.step;
              stepFound = true;
              break;
            }
          }
        }
    }

    if (!payload[0]?.values[0] || !payload[0]?.values[1]) {
      // @ts-ignore
      dispatch({ type: InfoActionTypes.NAVIGATE, payload: 1 });
    } else {
      // @ts-ignore
      dispatch({ type: InfoActionTypes.NAVIGATE, payload: step });
    }
  }, [data?.show_info, data?.needs_info_update]);

  const addSkillToBlacklist = (suggestion: any) => {
    setAvailableSkillsSuggestions(prevSuggestions => prevSuggestions.filter(item => item.id !== suggestion.id));
    const payload = {
      type: 'skills',
      skill_id: suggestion.skill_id
    };

    (async () => {
      try {
        await postRemoveSuggestion({
          roleId: suggestion.role_id,
          payload
        }).unwrap()
          // @ts-ignore
          .then((response) => {
            console.log('response', response)
          });
      } catch (e) {
        console.log('error', e)
      }
    })();
  }

  const addTitleToBlacklist = (suggestion: any) => {
    setAvailableTitlesSuggestions(prevSuggestions => prevSuggestions.filter(item => item.id !== suggestion.id));

    const payload = {
      type: 'job_titles',
      job_title_id: suggestion.job_title_id
    };

    (async () => {
      try {
        await postRemoveSuggestion({
          roleId: suggestion.role_id,
          payload
        }).unwrap()
          // @ts-ignore
          .then((response) => {
          });
      } catch (e) {
        console.log('error', e)
      }
    })();
  }

  const addIndustryToBlacklist = (suggestion: any) => {

    setAvailableIndustriesSuggestions(prevSuggestions => prevSuggestions.filter(item => item.id !== suggestion.id));
    const payload = {
      type: 'industries',
      industry_id: suggestion.industry_id
    };

    (async () => {
      try {
        await postRemoveSuggestion({
          roleId: suggestion.role_id,
          payload
        }).unwrap()
          // @ts-ignore
          .then((response) => {
            console.log('response', response)
          });
      } catch (e) {
        console.log('error', e)
      }
    })();
  }

  const addYearsOfExperienceToBlacklist = (suggestion: any) => {
    setAvailableYearsOfExperienceSuggestions(prevSuggestions => prevSuggestions.filter(item => item.id !== suggestion.id));
    const payload = {
      type: 'years_of_experience',
      years_of_experience_id: null
    };

    (async () => {
      try {
        await postRemoveSuggestion({
          roleId: suggestion.role_id,
          payload
        }).unwrap()
          // @ts-ignore
          .then((response) => {
            console.log('response', response)
          });
      } catch (e) {
        console.log('error', e)
      }
    })();
  }

  const removeSkillSuggestionFromList = (suggestion: any) => {
    setAvailableSkillsSuggestions(prevSuggestions => prevSuggestions.filter(item => item.id !== suggestion.id));
  }

  const removeTitleSuggestionFromList = (suggestion: any) => {
    setAvailableTitlesSuggestions(prevSuggestions => prevSuggestions.filter(item => item.id !== suggestion.id));
  }

  const removeIndustrySuggestionFromList = (suggestion: any) => {
    setAvailableIndustriesSuggestions(prevSuggestions => prevSuggestions.filter(item => item.id !== suggestion.id));
  }

  const removeYearsOfExperienceFromSuggestions = (suggestion: any) => {
    setAvailableYearsOfExperienceSuggestions(prevSuggestions => prevSuggestions.filter(item => item.id !== suggestion.id));
  }

  const leftAction =
    state.step === 1 ? null : <FontAwesomeIcon icon={faChevronLeft} onClick={prevStep} color={'#4f4f4f'} />;

  const showRoleMembers = state.step === (data?.sourcing_only ? 4 : 6);

  return (
    <>
      {withTitle && withCloseButton && (
        <TitleWithClose id={id} leftChild={leftAction} title={innerProps.roleName} mb={20} />
      )}

      <Grid mt={origin === 'roleSetup' ? 20 : 0}>
        <Grid.Col md={3} xs={12} style={{ paddingLeft: 0, paddingRight: 10 }}>
          <Stepper
            onClick={handleMenuItemClicked}
            classes={classes}
            step={state.step}
            showInfo={data?.show_info ? data.show_info : false}
            isDesktop={isDesktop}
            sourcing_only={data?.sourcing_only || false}
          />
        </Grid.Col>

        <Grid.Col
          md={9}
          xs={12}
          style={{ maxHeight: '84vh', overflow: 'auto', flexGrow: 0, paddingLeft: 10, paddingRight: 5 }}
        >
          {state.step === 1 && (
            <InitialDataStep
              values={state.values}
              isSubmitting={state.isSubmitting}
              isFetching={isFetching}
              next={requestAiSkills}
              save={requestAiSkillsAndFinishLater}
              showFinishLater={state.showInfo}
              // needsUpdate={data?.needs_info_update ?? false}
              needsUpdate={false}
              isLoading={state.isLoading}
            />
          )}

          {!data?.sourcing_only && state.step === 2 && !isFetching && (
            <ScrollArea offsetScrollbars scrollbarSize={5} type="auto" style={{ height: '100%', paddingRight: '10px' }}>
              <JobInfoStep
                values={state.values}
                originalData={{ ...data?.info, custom_template: data?.custom_template }}
                isSubmitting={state.isSubmitting}
                prev={prevStep}
                next={nextStep}
                save={save}
                showFinishLater={state.showInfo}
                sourcing_only={data?.sourcing_only ? data.sourcing_only : false}
                showInfo={data?.show_info ? data.show_info : false}
                isAdmin={isAdmin}
                oldLocation={data?.info?.office_locations ?? ''}
                onUnmount={handleUpdateStateValues}
                setEmailModified={setEmailModified}
                // the below line was added due to ticket T3-2696
                isCandidateLocationEmpty={isCandidateLocationEmpty}
              />
            </ScrollArea>
          )}

          {state.step === (data?.sourcing_only ? 2 : 3) && !isFetching && (
            <div style={{ maxHeight: '84vh' }}>
              <ScrollArea offsetScrollbars scrollbarSize={5} type="auto" style={{ height: '100%' }}>
                <LocationStep
                  values={state.values}
                  originalData={data?.info}
                  isSubmitting={state.isSubmitting}
                  prev={prevStep}
                  next={nextStep}
                  save={save}
                  showFinishLater={state.showInfo}
                  sourcing_only={data?.sourcing_only ? data.sourcing_only : false}
                  showInfo={data?.show_info ? data.show_info : false}
                  isAdmin={isAdmin}
                  oldLocation={data?.info?.office_locations ?? ''}
                  onUnmount={handleUpdateStateValues}
                  handleCandidateLocationChanges={handleCandidateLocationChanges}
                />
              </ScrollArea>
            </div>
          )}

          {!data?.sourcing_only && state.step === 4 && !isFetching && (
            <div style={{ maxHeight: '84vh' }}>
              <ScrollArea offsetScrollbars scrollbarSize={5} type="auto" style={{ height: '100%', overflow: 'hidden' }}>
                <HiringTeamStep
                  values={state.values}
                  originalData={data?.info}
                  isSubmitting={state.isSubmitting}
                  prev={prevStep}
                  next={nextStep}
                  save={save}
                  showFinishLater={state.showInfo}
                  companyId={company?.id}
                  onUnmount={handleUpdateStateValues}
                  // the below line was added due to ticket T3-2696
                  isCandidateLocationEmpty={isCandidateLocationEmpty}
                />
              </ScrollArea>
            </div>
          )}

          {state.step === (data?.sourcing_only ? 3 : 5) && state.showInfo && (
            <div style={{ maxHeight: '84vh' }}>
              <ScrollArea offsetScrollbars scrollbarSize={5} type="auto" style={{ height: '100%' }}>
                <SearchCriteriaStep
                  roleName={innerProps.roleName}
                  roleId={roleId}
                  values={{
                    ...state.values,
                    suggestedSkills: data?.search_criteria_skills_suggestions || [],
                    suggestedJobTitles: data?.search_criteria_titles_suggestions || [],
                    suggestedIndustries: data?.search_criteria_industry_suggestions || [],
                    suggestedYearsOfExperience: data?.search_criteria_years_experience_suggestions || null
                  }}
                  originalData={{
                    ...data?.user_searches[0],
                    info: data?.info,
                    default_industry: getDefaultIndustry(data),
                  }}
                  isSubmitting={state.isSubmitting}
                  isFetching={isFetching}
                  submit={submit}
                  prev={prevStep}
                  next={nextStep}
                  save={save}
                  sourcing_only={data?.sourcing_only ? data.sourcing_only : false}
                  showFinishLater={state.showInfo}
                  hasSearchData={data?.user_searches.length > 0 ? true : false}
                  roleInfo={data?.info}
                  onUnmount={handleUpdateStateValues}
                  skillsSuggestions={availableSkillsSuggestions}
                  titlesSuggestions={availableTitlesSuggestions}
                  addSkillToBlacklist={addSkillToBlacklist}
                  addTitleToBlacklist={addTitleToBlacklist}
                  removeSkillFromSuggestions={removeSkillSuggestionFromList}
                  removeTitleFromSuggestions={removeTitleSuggestionFromList}
                  removeIndustryFromSuggestions={removeIndustrySuggestionFromList}
                  addIndustryToBlacklist={addIndustryToBlacklist}
                  addYearsOfExperienceToBlacklist={addYearsOfExperienceToBlacklist}
                  industriesSuggestions={availableIndustriesSuggestions}
                  yearsOfExperienceSuggestions={availableYearsOfExperienceSuggestions[0]}
                  removeYearsOfExperienceFromSuggestions={removeYearsOfExperienceFromSuggestions}
                  // the below line was added due to ticket T3-2696
                  isCandidateLocationEmpty={isCandidateLocationEmpty}
                />
              </ScrollArea>
            </div>
          )}

          {state.step === (data?.sourcing_only ? 3 : 5) && !state.showInfo && (
            <RolebotTabs active={tab} onTabChange={setTab} origin="role-info">
              <Tabs.Tab label={'Search Criteria'}>
                <div style={{ maxHeight: '70vh' }}>
                  <ScrollArea offsetScrollbars scrollbarSize={5} type="auto" style={{ height: '100%' }}>
                    <SearchCriteriaStep
                      roleName={innerProps.roleName}
                      roleId={roleId}
                      values={{
                        ...state.values,
                        suggestedSkills: data?.search_criteria_skills_suggestions || [],
                        suggestedJobTitles: data?.search_criteria_titles_suggestions || [],
                        suggestedIndustries: data?.search_criteria_industry_suggestions || [],
                        suggestedYearsOfExperience: data?.search_criteria_years_experience_suggestions || null
                      }}
                      originalData={{
                        ...data?.user_searches[0],
                        info: data?.info,
                        default_industry: getDefaultIndustry(data),
                      }}
                      isSubmitting={state.isSubmitting}
                      isFetching={isFetching}
                      submit={submit}
                      prev={prevStep}
                      next={nextStep}
                      save={save}
                      sourcing_only={data?.sourcing_only ? data.sourcing_only : false}
                      showFinishLater={state.showInfo}
                      hasSearchData={data?.user_searches.length > 0 ? true : false}
                      roleInfo={data?.info}
                      onUnmount={handleUpdateStateValues}
                      skillsSuggestions={availableSkillsSuggestions}
                      titlesSuggestions={availableTitlesSuggestions}
                      addSkillToBlacklist={addSkillToBlacklist}
                      addTitleToBlacklist={addTitleToBlacklist}
                      removeSkillFromSuggestions={removeSkillSuggestionFromList}
                      removeTitleFromSuggestions={removeTitleSuggestionFromList}
                      removeIndustryFromSuggestions={removeIndustrySuggestionFromList}
                      industriesSuggestions={availableIndustriesSuggestions}
                      yearsOfExperienceSuggestions={availableYearsOfExperienceSuggestions[0]}
                      removeYearsOfExperienceFromSuggestions={removeYearsOfExperienceFromSuggestions}
                      addIndustryToBlacklist={addIndustryToBlacklist}
                      addYearsOfExperienceToBlacklist={addYearsOfExperienceToBlacklist}
                      // the below line was added due to ticket T3-2696
                      isCandidateLocationEmpty={isCandidateLocationEmpty}
                    />
                  </ScrollArea>
                </div>
              </Tabs.Tab>

              <Tabs.Tab label={'Feedback'}>
                <div style={{ maxHeight: '70vh' }}>
                  <FeedbackTab roleId={roleId} />
                </div>
              </Tabs.Tab>

              <Tabs.Tab label={'Role Activity'}>
                <div style={{ maxHeight: '70vh' }}>
                  <ScrollArea offsetScrollbars scrollbarSize={5} type="auto" style={{ height: '100%' }}>
                    <RoleActivityTab roleId={roleId} />
                  </ScrollArea>
                </div>
              </Tabs.Tab>
            </RolebotTabs>
          )}

          {/* {!state.showInfo && state.step === (data?.sourcing_only? 4 : 6) && ( */}
          {showRoleMembers && (
            <RoleAdmins
              context={context}
              innerProps={{ roleId, roleName, origin: origin ? origin : 'settings' }}
              id={''}
            />
          )}
        </Grid.Col>
      </Grid>
    </>
  );
};

export default RoleInfo;