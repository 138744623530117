import { useEffect, useMemo, useState, memo, useReducer, useRef } from 'react';
import { Button, Container, createStyles, Group, MediaQuery, Tabs, Text, Title, Tooltip } from '@mantine/core';
import RoleStack from 'components/RoleStack/RoleStack';
import Filters from 'components/Filters/Filters';
import useAppSelector from 'hooks/useAppSelector';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useGetCompanyQuery, useGetUserQuery } from 'app/services/rolebot';
import { useDebouncedValue, useMediaQuery } from '@mantine/hooks';
import RolebotTabs from 'components/RolebotTabs/RolebotTabs';
import SearchInput from 'components/SearchInput/SearchInput';
import Sorts from 'components/Sorts/Sorts';
import { buildFilterPayload, buildSortPayload } from 'utils';
import RoleLimitAlert from 'components/RoleLimitAlert/RoleLimitAlert';
import RolesOverlay from 'components/Overlays/RolesOverlay';
import useOpenModal from 'hooks/useOpenModal';
import { sendGoogleEvent } from 'utils/analytics';
import { setWaitingForSetupRolesCount, setCustomerPlanIsActive } from '../../features/app/appSlice';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import MobileRoleTopPageHeader from './Components/MobileRoleTopPageHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import TopMessage from 'components/TopMessage/TopMessage';
import { RolebotClient } from 'api/client';
import InactivePlanAlert from 'components/InactivePlanAlert/InactivePlanAlert';
import ActionPendingAlert from 'components/ActionPendingAlert/ActionPendingAlert';
import Echo from 'utils/echo';
import { toastWarning } from 'utils/toastify-messages';
import { setCurrentRoleId } from 'features/app/appSlice';

const useStyles = createStyles((theme) => ({
  container: {
    paddingInline: 10,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',

    [theme.fn.largerThan('md')]: {
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      paddingInline: 30,
    },
  },

  topMenu: {
    display: 'none',
    [theme.fn.largerThan('md')]: {
      display: 'flex',
    },
  },

  col: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  inputIcon: {
    height: 50,
    width: 50,
    marginLeft: 15,
  },
}));

enum SUB_TABS {
  active,
  completed,
}

const reducer = (state: any, action: any) => {
  switch(action.type) {
    case 'onTabChange':
        return {
            ...state,
            activeTabIndex: action.payload.index,
            activeTab: action.payload.tabKey,
            query: ''
        };
    case 'onRequestMade':
        return {
            ...state,
            isFetching: true
        };
    case 'onQueryChange':
      return {
        ...state,
        query: action.payload.query
      };
    case 'getRolesReturned':
      const { data, type, total } = action.payload;

      let activeRoles = state.activeRoles;
      let completedRoles = state.completedRoles;

      if (type === 'active') {
        activeRoles = [...activeRoles, ...data];
      }

      if (type === 'completed') {
        completedRoles = [...completedRoles, ...data];
      }

      return {
        ...state,
        isFetching: false,
        activeRoles: activeRoles,
        completedRoles: completedRoles,
        activeRolesCount: type === 'active' ? total : state.activeRolesCount,
        completedRolesCount: type === 'completed' ? total : state.completedRolesCount
      }
    case 'resetRolesList':
      const { roles, roleType, totalCount } = action.payload;

      return {
        ...state,
        activeRoles: roleType === 'active' ? roles : state.activeRoles,
        completedRoles: roleType === 'completed' ? roles : state.completedRoles,
        activeRolesCount: roleType === 'active' ? totalCount : state.activeRolesCount,
        completedRolesCount: roleType === 'completed' ? totalCount : state.completedRolesCount
      }
  }
}

const Roles = () => {
  const [state, dispatch] = useReducer(reducer, {
    isFetching: false,
    activeTabIndex: 0,
    activeTab: 'active',
    activeRoles: [],
    activeRolesCount: 0,
    completedRoles: [],
    completedRolesCount: 0,
    query: '',
    perPage: 15
  });

  const filters = useAppSelector((state) => state.app.filters);
  const sortType = useAppSelector((state) => state.app.sortType);
  const search = useAppSelector((state) => state.router.location?.search);
  const { data: company, isLoading: isCompanyLoading } = useGetCompanyQuery();
  const navigate = useNavigate();
  const appDispatch = useAppDispatch();
  const { classes } = useStyles();
  
  const isDesktop = useMediaQuery('(min-width: 992px)');
  const { launchRole, roleLimitReachedModal } = useOpenModal();
  const [debouncedSearch] = useDebouncedValue(state.query.trim(), 300);

  const filtersPayload = useMemo(() => buildFilterPayload(filters), [filters]);
  const sortPayload = useMemo(() => buildSortPayload(sortType), [sortType]);

  const isFirstRenderRef = useRef(true);

  const [searchParams, setSearchParams] = useSearchParams();
  const mobileView = ((searchParams.get('status') === 'active' || searchParams.get('status') === null)) ? 'active' : 'completed'

  const user = useGetUserQuery();
  const userId = user?.data?.user?.id;

  const getRoleList = (request: { 'role-filter-type': string; page: number, 'per_page': number, search?: string}) => {
    ( async () => {
      try {
        const { data } = await RolebotClient.get('/roles', {	params: request	})
        dispatch({ type: 'getRolesReturned', payload: {...data, type: request['role-filter-type']}}); 
      } catch (error) {
        console.log(error);
      }
    })();
  }

  const getPageNumber = () => {
    if (!state.activeTab) return 1;

    const rolesCount = state[state.activeTab + 'RolesCount'];
    const currentTab = state[state.activeTab + 'Roles'];

    if (!currentTab) return 1;
    
    if (currentTab.length === 0) return 1;

    if (rolesCount - currentTab.length === 0) return -1;

    let pageNumber = Math.ceil(currentTab.length / state.perPage);
    return ++pageNumber;
  };


  /**
   * This block of code together with the mobileView variable are ugly patches to the fact that the mobile navbar options were
   * not connected to the /roles page whatsover, so we needed to implement this ugly fix until we move on to responsive design
   */
  useEffect(() => {
    if (!isDesktop) {
      dispatch({ type: 'onTabChange', payload: {index: mobileView === 'active' ? 1 : 2, tabKey: mobileView}});
      //when changing tabs, we load the role list from scratch.
      dispatch({ type: 'resetRolesList', payload: {
        roles: [],
        roleType: mobileView,
        totalCount: 0
      }});
      //get roles for the tab
      const request = {
        'role-filter-type': mobileView,
        page: 1,
        'per_page': state.perPage,
        ...sortPayload,
        ...filtersPayload
      };
      dispatch({ type: 'onRequestMade' })
      getRoleList(request);
    }
  }, [mobileView])

  const handleTabChange = (index: number, tabKey: any) => {
    dispatch({ type: 'onTabChange', payload: {index: index, tabKey: tabKey}});
    //when changing tabs, we load the role list from scratch.
    dispatch({ type: 'resetRolesList', payload: {
      roles: [],
      roleType: tabKey,
      totalCount: 0
    }});
    //get roles for the tab
    const request = {
      'role-filter-type': tabKey,
      page: 1,
      'per_page': state.perPage,
      ...sortPayload,
      ...filtersPayload
    };
    dispatch({ type: 'onRequestMade' })
    getRoleList(request);
  }

  const handleChangeQuery = (query: string) => {
    dispatch({ type: 'onQueryChange', payload: {query: query}});
  }

  const handleGetMoreRoles = () => {
    if (state.activeTab && !state.isFetching) {
      const pageNumber = getPageNumber();
      if (pageNumber !== -1) {
        const request = {
          'role-filter-type': state.activeTab,
          page: pageNumber,
          'per_page': state.perPage,
          ...sortPayload,
          ...filtersPayload
        };

        if (debouncedSearch !== '') {
          // @ts-ignore
          request['search'] = debouncedSearch;
        }

        dispatch({ type: 'onRequestMade' })
        getRoleList(request);
      }
    }
  };

  const updateRoleListAfterRoleResume = (roleId: string | number) => {
    let updatedActiveRoles = state.activeRoles.map((role: any) => {
      if (role.id === roleId) {
        role.on_hold = false;
      }
      return role;
    });

    dispatch({ type: 'resetRolesList', payload: {
      roles: updatedActiveRoles,
      roleType: 'active',
      totalCount: state.activeRolesCount
    }});
  }

  const updateRoleListAfterRolePause = (roleId: string | number) => {
    let updatedActiveRoles = state.activeRoles.map((role: any) => {
      if (role.id === roleId) {
        role.on_hold = true;
      }
      return role;
    });

    dispatch({ type: 'resetRolesList', payload: {
      roles: updatedActiveRoles,
      roleType: 'active',
      totalCount: state.activeRolesCount
    }});
  }

  const updateRoleListAfterRoleClose = (roleId: string | number) => {
    let updatedActiveRoles = state.activeRoles.filter((role: any) => role.id !== roleId);

    dispatch({ type: 'resetRolesList', payload: {
      roles: updatedActiveRoles,
      roleType: 'active',
      totalCount: state.activeRolesCount
    }});
  }

  const updateRoleListAfterRoleOpen = (roleId: string | number) => {
    let updatedCompletedRoles = state.completedRoles.filter((role: any) => role.id !== roleId);

    dispatch({ type: 'resetRolesList', payload: {
      roles: updatedCompletedRoles,
      roleType: 'completed',
      totalCount: state.completedRolesCount
    }});
  }

  const updateRoleListAfterRoleReactivate = (roleId: string | number) => {
    let updatedCompletedRoles = state.completedRoles.map((role: any) => {
      if (role.id === roleId) {
        role.role_requests.push({
          type: 'open',
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString()
        });
      }
      return role;
    });

    dispatch({ type: 'resetRolesList', payload: {
      roles: updatedCompletedRoles,
      roleType: 'completed',
      totalCount: state.completedRolesCount
    }});
  }

  const handleSetupComplete = (isCompleted: boolean, roleId: number) => {
    refreshActiveTab();
  }

  const refreshActiveTab = () => {
    dispatch({ type: 'resetRolesList', payload: {
      roles: [],
      roleType: state.activeTab,
      totalCount: 0
    }});

    //get roles for the tab
    const request = {
      'role-filter-type': state.activeTab,
      page: 1,
      'per_page': state.perPage,
      ...sortPayload,
      ...filtersPayload
    };

    if (debouncedSearch !== '') {
      // @ts-ignore
      request['search'] = debouncedSearch;
    }

    dispatch({ type: 'onRequestMade' })
    getRoleList(request);
  }

  const handlePatchCompleted = (action: string, roleId: string | number) => {
    if (action === 'resumeRole') {
      updateRoleListAfterRoleResume(roleId);
    }

    if (action === 'pauseRole') {
      updateRoleListAfterRolePause(roleId);
    }

    if (action === 'closeRole') {
      updateRoleListAfterRoleClose(roleId);
    }

    if (action === 'openRole') {
      updateRoleListAfterRoleOpen(roleId);
    }

    if (action === 'reactivateRole') {
      updateRoleListAfterRoleReactivate(roleId);
    }

    if (action === 'launchRole') {
      //when we launch a role, we just update the current list of roles
      refreshActiveTab();
    }
  }

  useEffect(() => {
    if (isDesktop) {
      handleGetMoreRoles();
    }
  }, [])

  useEffect(() => {
    // @ts-ignore
    let waitingForSetupRolesCount = state?.activeRoles?.filter((x) => x.show_info).length;
    appDispatch(setWaitingForSetupRolesCount(waitingForSetupRolesCount));
    // eslint-disable-next-line
  }, [state.activeRoles]);

  useEffect(() => {

    if (isFirstRenderRef.current) {
      isFirstRenderRef.current = false;
      return;
    }

    if (!state.isFetching) {
      dispatch({ type: 'resetRolesList', payload: {
        roles: [],
        roleType: state.activeTab,
        totalCount: 0
      }});
      //get roles for the tab
      const request = {
        'role-filter-type': state.activeTab,
        page: 1,
        'per_page': state.perPage,
        ...sortPayload,
        ...filtersPayload
      };

      if (debouncedSearch !== '') {
        // @ts-ignore
        request['search'] = debouncedSearch;
      }

      dispatch({ type: 'onRequestMade' })
      getRoleList(request);
    }
  }, [filtersPayload, debouncedSearch, sortPayload]);

  useEffect(() => {
    if (company) {
      appDispatch(setCustomerPlanIsActive(company.is_active));
    }
  }, [company, appDispatch]);

  const openLaunchModal = () => {
    sendGoogleEvent('User', 'New Role button clicked');
    //avoid click when we dont have any company data...
    if (company?.active_role_limit && company?.active_role_count >= company?.active_role_limit) {
      roleLimitReachedModal({ company: company });
    } else {
      launchRole({
        onPatchCompleted: handlePatchCompleted
      });
    }
  };

  const handleReviewBatch = (roleName: string, roleId: string | number) => () => {
    dispatch(setCurrentRoleId(roleId));
    navigate(roleId.toString(), {
      state: {
        role: roleName,
      },
    });
  }

  const NewBatchForRoleMessage = (roleName: string, roleId: string | number) => (
    <Text
      style={{
        fontFamily: 'Helvetica',
        fontSize: '14px',
        fontWeight: 400
      }}
    >
      Rolebot has found potential matches for your "{roleName}" role. <a onClick={handleReviewBatch(roleName,roleId)} style={{ cursor: 'pointer', textDecoration: 'underline', color: '#249FC8' }}>Check them out now!</a>
    </Text>
  );

  useEffect(() => {
    if (process.env.REACT_APP_WEB_SOCKETS_DISABLED === 'false' || !process.env.REACT_APP_WEB_SOCKETS_DISABLED) {
      Echo.channel(`new-batch-for-role${userId}`).listen('NewBatchForRole', async (e: any) => {
        toastWarning(
          <div className="toastContainer">
            <div>
              Wohoo! New candidates!
            </div>
            <div>{NewBatchForRoleMessage(e.roleName, e.roleId)}</div>
          </div>
        );
      });
      return () => Echo.leaveChannel(`new-batch-for-role${userId}`);
    }
  }, [userId]);

  const activeRolesOverlay = (
    <RolesOverlay
      type="active"
      isSearching={debouncedSearch !== ''}
      isInitialized={state.isFetching}
      hasData={state.activeRolesCount > 0}
      isFetching={state.isFetching}
      page={getPageNumber()}
    >
      {state.activeRolesCount > 0 &&
        <RoleStack
          data={state.activeRoles}
          isFetching={state.isFetching}
          page={getPageNumber()}
          onEndReached={handleGetMoreRoles}
          handlePatchCompleted={handlePatchCompleted}
          handleSetupComplete={handleSetupComplete}
        />}
    </RolesOverlay>
  );

  const completedRolesOverlay = (
    <RolesOverlay
      type="completed"
      isSearching={debouncedSearch !== ''}
      isInitialized={state.isFetching}
      hasData={state.completedRolesCount > 0}
      isFetching={state.isFetching}
      page={getPageNumber()}
    >
      {state.completedRolesCount > 0 &&
        <RoleStack
          data={state.completedRoles}
          isFetching={state.isFetching}
          page={getPageNumber()}
          onEndReached={handleGetMoreRoles}
          handlePatchCompleted={handlePatchCompleted}
          handleSetupComplete={() => {}}
        />}
    </RolesOverlay>
  );

  return (
    <div style={{
      paddingLeft: '0px',
      paddingRight: '0px',
    }} className={classes.container}>
      <div style={{
            position: 'sticky',
            top: 0
          }}>
          {(!isCompanyLoading && !company?.is_active && !company?.customer_pricing_plan?.requires_reactivation) ? <InactivePlanAlert /> : null}
          {(!isCompanyLoading && company?.customer_pricing_plan?.requires_reactivation) ? <ActionPendingAlert /> : null}
          <RoleLimitAlert threshold={75}/>
          <TopMessage />
      </div>
      <Container size={'xl'} className={classes.container}>
        <MediaQuery styles={{ display: 'none' }} smallerThan={'md'}>
          <Group position={'apart'} mt={40}>
            <Title style={{color: '#242424'}} order={2}>All Roles</Title>
            <Group spacing={0} align={'center'}>
              <Group spacing={4} sx={{ color: '#4f4f4f' }}>
                <Text sx={{ fontFamily: 'Roboto', fontWeight: 500 }}>{company?.active_role_count}</Text>
                <Text color={'#838485'}>Active Roles</Text>
              </Group>
              {company?.active_role_limit && (
                <>
                  <Text mx={6} color={'#B3B3B3'}>
                    /
                  </Text>
                  <Group spacing={4} sx={{ color: '#4f4f4f' }}>
                    <Text sx={{ fontFamily: 'Roboto', fontWeight: 500 }}>{company.active_role_limit}</Text>
                    <Text color={'#838485'}>Role Limit</Text>
                  </Group>
                </>
              )}
            </Group>
          </Group>
        </MediaQuery>
        <MediaQuery styles={{ display: 'none' }} smallerThan={'md'}>
          <Group spacing={0} my={30}>
            <SearchInput value={state.query} onChange={handleChangeQuery} sx={{ minWidth: 200, maxWidth: 500, flex: 1 }} />
            <Group noWrap spacing={10} ml={20}>
              <Filters roleType={company?.role_type.name} />
              <Sorts />
            </Group>
            <Tooltip
              ml={"auto"}
              label={!company?.is_active ? "Reactivate your plan to unlock this feature" : ''}
              disabled={!!company?.is_active}
              position="top"
              withArrow
            >
              <div> 
                <Button
                  size={'md'}
                  leftIcon={<FontAwesomeIcon icon={faPlus} width={18} height={18} />}
                  onClick={openLaunchModal}
                  disabled={!company?.is_active}
                >
                  New Role
                </Button>
              </div>
            </Tooltip>
          </Group>
        </MediaQuery>

        <MobileRoleTopPageHeader title={mobileView} />

        {isDesktop && (
          <RolebotTabs
            initialTab={state.activeTabIndex}
            active={state.activeTabIndex}
            onTabChange={(tabIndex, tabKey) => {
              handleTabChange(tabIndex, tabKey);
            }}
          >
            <Tabs.Tab label="Active Roles" tabKey='active'>{activeRolesOverlay}</Tabs.Tab>
            <Tabs.Tab label="Completed Roles" tabKey='completed'>{completedRolesOverlay}</Tabs.Tab>
          </RolebotTabs>
        )}

        {!isDesktop && mobileView === 'active' && (activeRolesOverlay)}
        {!isDesktop && mobileView === 'completed' && (completedRolesOverlay)}
      </Container>
    </div>
  );
};

export default memo(Roles);

